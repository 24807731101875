<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-add-wrapper">
      <validation-observer ref="addRatingConfig">
        <!-- form -->
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <b-form-group
                label="Name"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="product-add-name"
                    v-model="peRule.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Gender -->
            <b-col md="4">
              <b-form-group
                label="Gender"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  rules="required"
                >
                  <v-select
                    id="product-add-category"
                    v-model="peRule.gender"
                    :options="genderOptions"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Status -->
            <b-col md="2">
              <b-form-group
                label=""
                class="mb-2 d-flex"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="isActive"
                    name="check-button"
                    style="margin-top: calc(3rem - 9px)"
                    switch
                    inline
                  >
                    Active
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Start age -->
            <b-col md="3">
              <b-form-group
                label="Start age"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Start age"
                  rules="required"
                >
                  <b-form-input
                    id="product-start-age"
                    v-model="peRule.start_age"
                    type="number"
                    @input="validateStartAge"
                    min="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End age -->
            <b-col md="3">
              <b-form-group
                label="End age"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="End age"
                  rules="required"
                  type="number"
                >
                  <b-form-input
                    id="product-end-age"
                    v-model="peRule.end_age"
                    type="number"
                    @input="validateEndAge"
                    min="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Nationality -->
            <b-col md="6">
              <b-form-group
                label="Nationality"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <v-select
                    id="product-add-category"
                    v-model="peRule.nationality"
                    :options="countries"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="mb-2">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search..."
                />
              </div>
              <b-overlay
                :show="showTable"
                rounded="sm"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
              >
                <b-table
                  ref="refTagsTable"
                  class="position-relative"
                  :items="fetchPrograms"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="No matching records found"
                  :sort-desc.sync="isSortDirDesc"
                >
                  <!-- Column: preview -->
                  <template #cell(preview)="data">
                    <b-img
                      height="60"
                      width="100"
                      :src="VALID_IMAGE + data.item.image.key"
                    />
                  </template>
                  <!-- Column: Title -->
                  <template #cell(name)="data">
                    <b-media vertical-align="center">
                      <span>
                        {{ data.item.name }}
                      </span>
                    </b-media>
                  </template>
                  <!-- Column: # -->
                  <template #cell(#)="data">
                    <b-form-checkbox
                      :checked="data.item.selected"
                      @change="toggleSelection(data.item)"
                    />
                  </template>
                </b-table>
              </b-overlay>

              <div class="mx-2 mb-2">
                <b-row class="justify-content-center">
                  <!-- Pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalPrograms"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-row>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                style="margin-top: calc(3rem - 15px)"
                class="text-center"
              >
                <h3>Selected items</h3>
              </div>
              <b-table
                style="max-height: 500px; overflow-y: auto"
                striped
                hover
                class="position-relative"
                :items="selectedItems"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
              >
                <!-- Column: preview -->
                <template #cell(preview)="data">
                  <b-img
                    height="60"
                    width="100"
                    :src="VALID_IMAGE + data.item.image.key"
                  />
                </template>
                <!-- Column: Title -->
                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    <span>
                      {{ data.item.name }}
                    </span>
                  </b-media>
                </template>
                <!-- Column: # -->
                <template #cell(#)="data">
                  <b-link @click="deleteSelectedItem(data.item)">
                    Delete
                  </b-link>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="add"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'pe-rule' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BFormRadio,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BFormFile,
  BImg,
  BOverlay,
  BPagination,
  BTable,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
import { VALID_IMAGE } from '@/utils/config'
import addProgramCategory from './addRatingConfig'
import useTable from '../useTable'

export default {
  components: {
    vSelect,
    BFormRadio,
    BCardBody,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BFormFile,
    BImg,
    BOverlay,
    BPagination,
    BTable,
    BImg,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      numberDefault: null,
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions', 'statusOptions']),
    ...mapGetters('peRule', ['optionsActive', 'genderOptions', 'countries']),
  },
  setup() {
    const {
      status, arrayRatingConfig, countRatingConfig, peRule, isActive,
    } = addProgramCategory()

    const {
      timeout,
      showTable,
      fetchPrograms,
      tableColumns,
      perPage,
      currentPage,
      totalPrograms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      resolveStatusProgram,
      selectedItems,
    } = useTable()
    return {
      status,
      arrayRatingConfig,
      countRatingConfig,
      peRule,

      timeout,
      showTable,
      fetchPrograms,
      tableColumns,
      perPage,
      currentPage,
      totalPrograms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      resolveStatusProgram,
      VALID_IMAGE,
      selectedItems,
      isActive,
    }
  },
  methods: {
    validateEndAge(value) {
      if (value < 0) {
        this.peRule.end_age = 0 // Reset to 0 if the input is negative
      }
    },
    validateStartAge(value) {
      if (value < 0) {
        this.peRule.start_age = 0 // Reset to 0 if the input is negative
      }
    },
    changeSwitch() {},
    toggleSelection(item) {
      item.selected = !item.selected
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems = this.selectedItems.filter(i => i.id !== item.id)
      }
      console.log(this.selectedItems)
    },
    deleteSelectedItem(item) {
      this.selectedItems = this.selectedItems.filter(i => i.id !== item.id)
      this.refetchData()
    },
    add() {
      this.$refs.addRatingConfig.validate().then(success => {
        if (success) {
          const payload = {
            name: this.peRule.name,
            gender: this.peRule.gender.value,
            start_age: Number(this.peRule.start_age),
            end_age: Number(this.peRule.end_age),
            nationality: this.peRule.nationality.label,
            country_code: this.peRule.nationality.value,
            status: this.isActive,
            pe_programs: this.selectedItems.map(x => x.id),
          }
          store
            .dispatch('peRule/addPeRule', payload)
            .then(response => {
              if (response.request.status === 201) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Create config successfuly',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                router.push('/pe-rule')
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}
</style>
